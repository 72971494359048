<template>
    <div class="cg_main">
        <div style="width: 100%;height: 5px;background: #EFEFEF; "></div>
        <div class="cg_main_son">
            <div style=" display: flow-root;margin-bottom: 25px;margin-top: 25px;">
                <div style="float: left">
                    <el-breadcrumb separator="/" style="padding-top: 13px">
                        <el-breadcrumb-item><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item>我的项目</el-breadcrumb-item>
                    </el-breadcrumb>
                    <!--<el-button type="primary" @click="openDia">新增</el-button>-->
                    <!--<el-button type="primary" >已完成</el-button>
                    <el-button >未完成</el-button>-->
                </div>
                <div style="float: right">
                    <el-button type="primary" @click="openDia">新增</el-button>
                   <!-- <el-input style="width: 240px;" placeholder="请输入产品信息" v-model="input3" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>-->
                </div>

            </div>
            <div style="margin-bottom: 40px;">
                <el-table
                        :data="tableData"
                        style="width: 100%;padding: 30px;">
                    <el-table-column
                            prop="name"
                            label="项目名称"
                            width="180" align="center">
                        <template slot-scope="scope">
                            <div style="color:rgb(10, 142, 255);text-decoration:underline;cursor:pointer;" @click="openDetialDia(scope.row.id,scope.row.name)">
                                {{scope.row.name }}
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="descript"
                            label="项目描述"
                            width="380"
                            show-overflow-tooltip
                    >
                    </el-table-column>
                    <!--<el-table-column-->
                            <!--prop="total"-->
                            <!--label="套数">-->
                    <!--</el-table-column>-->
                    <!--<el-table-column-->
                            <!--prop="catalogutPrice"-->
                            <!--label="目录总价">-->
                    <!--</el-table-column>-->
                    <!--<el-table-column-->
                            <!--prop="internalPrice"-->
                            <!--label="内部总价">-->
                    <!--</el-table-column>-->
                    <el-table-column
                            prop="projectTime"
                            label="项目时间">
                    </el-table-column>

                    <el-table-column
                            prop="address"
                            label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" style="color: #0A8EFF;margin-right: 5px" @click="openEditDia(scope.row.id,scope.row.name)">
                                编辑
                            </el-button>
                           <!-- <el-button type="text" style="color: #0A8EFF;margin-right: 5px" @click="openDetialDia(scope.row.id,scope.row.name)">
                                查看
                            </el-button>-->
                            <el-popconfirm title="确定删除？" @confirm="delList(scope.row.id,scope.$index)">
                                <el-button type="text" style="color: #0A8EFF" slot="reference">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="tb_foot" >
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="page.pageNum"
                            :page-size="page.pageSize"
                            layout="total, prev, pager, next"
                            :total="page.total"
                    >
                    </el-pagination>
                </div>
                <!--<div class="tb_foot">

                    <div class="tbf_l">

                        <div class="tbf_l">
                            已显示 {{page.total}} 条记录
                        </div>
                    </div>
                    <div class="tbf_r">
                        目录总价：<span>￥1,068.00</span>   目录总价：<span>￥1,068.00</span>
                    </div>
                </div>-->
            </div >


        </div>
        <el-dialog title="选择项目" :visible.sync="dialogVisible" width="370px">
            <el-form  label-width="80px">

                <el-form-item label="项目名称" >
                    <el-input v-model="xmListNew.name" placeholder="请输入项目名称"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="isEdit" @click="addList">确 定</el-button>
                <el-button type="primary" v-if="!isEdit" @click="editList">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        standardList,
        projectList,
        projectAddNew,
        projectEdit,
        projectDel,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                isEdit:true,
                dialogVisible:false,
                tableData:[],
                editId:'',
                xmListNew:{
                    name:''
                },
                page: {
                    total: 0, // 总页数
                    pageNum: 1, // 当前页数
                    pageSize: 10 // 每页显示多少条
                },
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.page.pageNum = val;
                this.getprojectList();
            },
            openDetialDia(id,name){
                this.$router.push({name:'productList',query: {type:0,id:id,name:name}})
            },
            addList(){
                let obj = {
                    name: this.xmListNew.name,
                };
                let ss= encrypt(JSON.stringify(obj))
                projectAddNew(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        alert(dd.msg);
                        this.dialogVisible=false
                        this.getprojectList();
                    }else {
                        alert(dd.msg);
                    }

                })
            },

            editList(){
                let obj = {
                    name: this.xmListNew.name,
                    id:this.editId
                };
                let ss= encrypt(JSON.stringify(obj))
                projectEdit(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        alert(dd.msg);
                        this.dialogVisible=false
                        this.getprojectList();
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            delList(id,index){
                let obj = {
                    id: id,
                };
                let ss= encrypt(JSON.stringify(obj))
                projectDel(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.tableData.splice(index, 1)
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            openEditDia(id,name){
                this.dialogVisible=true
                this.xmListNew.name=name
                this.editId=id
                this.isEdit=false
            },
            openDia(){
                this.dialogVisible=true
                this.xmListNew.name=''
                this.isEdit=true
            },
            getprojectList(){
                let obj = {
                    page: this.page.pageNum,
                    size: this.page.pageSize,
                };
                let ss= encrypt(JSON.stringify(obj))
                projectList(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    console.log(dd)
                    if (dd.code == 200) {
                        this.tableData=dd.data.records
                        this.page.total=dd.data.total
                    }else {
                        alert(dd.msg);
                    }

                })

            },
        },
        mounted() {
            this.getprojectList()

        },
    }
</script>

<style lang="less" scoped>
.cg_main{
    background: white;
    height: 100%;
    display: flow-root;
    .cg_main_son{
        width: 1200px;
        margin: 0px auto;
        min-height: 499px;
        /deep/.el-table{
            padding: 0px !important;
        }
        /deep/.el-table th, .el-table tr{
            height: 60px!important;
            background: #EFEFEF!important;
        }
        .tb_foot{
            height: 60px;
            background: #F5F5F5;
            width: 100%;
            line-height: 60px;
            text-align: right;
            position: relative;
            padding-top: 12px;
            .tbf_l{
                float: left;
                font-size: 14px;
                color: #666666;
                padding-left: 30px;
            }
            .tbf_r{
                float: right;
                font-size: 14px;
                color: #333333;
                padding-right: 30px;
                span{
                    color: #E72A32;
                }
            }
        }
    }
}
</style>
